import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { colors } from "../../utils/theme";
import { useDispatch, useSelector } from "react-redux";
import { LOGIN_VIVE } from "../../viveMasRedux/actions/authViveActions";
import { useHistory } from "react-router-dom";
import { LOGO_VIVE } from "../../assets/images";
import { CircularProgress } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  avatar: {
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%" // Fix IE 11 issue.
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "#006ABF"
  },
  home: {
    margin: theme.spacing(0, 0, 2),
    backgroundColor: "#006ABF"
  },
  imageContainer: {
    width: "30%",
    height: "30%"
  },
  container: {
    display: "flex",
    alignItems: "center",
    height: "100vh",
    background: "#36ABE0"
  },
  input: {
    color: colors.white
  }
}));

function SignIn(props) {
  let history = useHistory();
  const dispatch = useDispatch();
  function handleClick() {
    history.push("/inicio");
  }

  const isLoading = useSelector(state => state.viveAuth.isLoading);

  const [input, setInput] = useState({});
  const [msg, setMsg] = useState(null);

  const handleInputChange = e =>
    setInput({
      ...input,
      [e.currentTarget.name]: e.currentTarget.value
    });

  const onSubmit = e => {
    const { email, password } = input;
    e.preventDefault();
    const user = {
      email: email,
      password: password
    };
    dispatch(LOGIN_VIVE(user));
  };

  const classes = useStyles();

  return !isLoading ? (
    <div className={classes.container}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <img className={classes.imageContainer} src={LOGO_VIVE} alt={"Alt"} />

          <form onSubmit={onSubmit} className={classes.form} noValidate>
            <TextField
              InputLabelProps={{
                style: {
                  width: "100%",
                  color: "white"
                }
              }}
              InputProps={{
                className: classes.input
              }}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              onChange={handleInputChange}
            />
            <TextField
              InputLabelProps={{
                style: {
                  width: "100%",
                  color: "white"
                }
              }}
              InputProps={{
                className: classes.input
              }}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={handleInputChange}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Iniciar sesión
            </Button>
          </form>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            className={classes.home}
            onClick={() => {
              history.push("/");
            }}
          >
            Inicio
          </Button>
        </div>
        <Box mt={8}></Box>
      </Container>
    </div>
  ) : (
    <div
      style={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: colors.main
      }}
    >
      <CircularProgress color={"secondary"} />
    </div>
  );
}

export default SignIn;
