import React, { useEffect, useState } from "react";
import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Link from "@material-ui/core/Link";
import Main from "../../layouts/Main";
import { Table } from "../../components/shared";
import TextField from "@material-ui/core/TextField";
import { LOGO_IMG, LOGO_VIVE } from "../../assets/images";
import { colors } from "../../utils/theme";
import { Modal } from "../../components/shared/index";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  _VIVE_ADD_BENEFIT,
  _VIVE_GET_BENEFITS
} from "../../viveMasRedux/actions/benefitViveActions";
import { CircularProgress } from "@material-ui/core";
import { _LOGOUT_VIVE } from "../../viveMasRedux/actions/authViveActions";

export function Copyright({ textColor }) {
  return (
    <Typography variant="body2" style={{ color: textColor }} align="center">
      {"Copyright © "}
      <Link
        color={textColor}
        style={{ color: textColor }}
        href="https://material-ui.com/"
      >
        E-me digital agency
      </Link>{" "}
      {new Date().getFullYear()}
    </Typography>
  );
}

const Home = props => {
  const classes = useStyles();
  const [input, setInput] = useState({});
  const dispatch = useDispatch();
  const benefits = useSelector(state => state.viveBenefit);
  console.log(benefits);
  const handleInputChange = e =>
    setInput({
      ...input,
      [e.currentTarget.name]: e.currentTarget.value
    });

  const onSubmit = async e => {
    e.preventDefault();
    const { code } = input;
    dispatch(_VIVE_ADD_BENEFIT(code));
  };

  const history = useHistory();
  useEffect(() => {
    dispatch(_VIVE_GET_BENEFITS());
  }, []);
  return (
    <Main>
      <CssBaseline />
      <AppBar className={classes.backPrime} position="relative">
        <div className={classes.containerCategory}>
          <div
            className={classes.containerCityPrime}
            onClick={() => {
              history.push("/city-prime/inicio");
            }}
          >
            <img
              className={classes.imageContainer}
              src={LOGO_IMG}
              alt={"Alt"}
            />
          </div>
          <div className={classes.containerViveMas}>
            <img
              className={classes.imageContainer}
              src={LOGO_VIVE}
              alt={"Alt"}
            />
          </div>
        </div>
        <div
          onClick={() => dispatch(_LOGOUT_VIVE())}
          className={classes.logout}
        >
          Cerrar sesión
        </div>
      </AppBar>
      <main>
        {/* Hero unit */}
        <div className={classes.heroContent}>
          <Container maxWidth="sm">
            <div>
              <form onSubmit={onSubmit} className={classes.form} noValidate>
                <TextField
                  onChange={handleInputChange}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="code"
                  label="Código"
                  name="code"
                  autoComplete="benefit"
                  autoFocus
                />
                <div className={classes.buttons}>
                  {props.loadingButton ? (
                    <div className={classes.loadingButton}>
                      <CircularProgress color={"primary"} />
                    </div>
                  ) : (
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.button}
                      value="benefit"
                      type="submit"
                    >
                      Canjear beneficio
                    </Button>
                  )}
                </div>
              </form>
            </div>
          </Container>
        </div>
        <Container className={classes.cardGrid} maxWidth="md">
          {/* End hero unit */}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <Grid item xs={12} sm={12} md={7}>
              <div className={classes.title}>Beneficios (Últimos 50)</div>
              <div onClick={() => {}} className={classes.buttonTitle}>
                Exportar
              </div>
              <Card className={classes.card}>
                <Table items={benefits.benefits} city={false} />
              </Card>
            </Grid>
          </div>
        </Container>
      </main>
      {/* Footer */}
      <footer className={classes.footer}>
        <Typography variant="h6" align="center" gutterBottom>
          Vive Mas
        </Typography>
        <Typography
          variant="subtitle1"
          align="center"
          color="textSecondary"
          component="p"
        >
          ¡Vive en estado City!
        </Typography>
        <Copyright />
      </footer>
      <Modal />
    </Main>
  );
};

export default Home;

const useStyles = makeStyles(theme => ({
  icon: {
    marginRight: theme.spacing(2)
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6)
  },
  heroButtons: {
    marginTop: theme.spacing(4)
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8)
  },
  card: {
    overflow: "scroll",
    height: 400,
    overflowX: "hidden",
    display: "flex",
    flexDirection: "column"
  },
  cardMedia: {
    paddingTop: "56.25%" // 16:9
  },
  cardContent: {
    flexGrow: 1
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6)
  },
  imageContainer: {
    width: "100%",
    height: "100%",
    alignSelf: "center",
    objectFit: "contain"
  },
  backPrime: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    background: "#36ABE0",
    alignItems: "flex-end",
    padding: "10px 80px 0 80px"
  },
  textButton: {
    color: colors.white
  },
  title: {
    fontWeight: "bold",
    marginBottom: -3,
    backgroundColor: colors.white,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    display: "inline-block",
    padding: 10,
    boxShadow: "2px 3px 5px 0px rgba(0,0,0,0.75)"
  },
  buttonTitle: {
    color: "white",
    fontWeight: "bold",
    marginBottom: -3,
    background: "#36ABE0",
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    display: "inline-block",
    padding: 10,
    boxShadow: "2px 3px 5px 0px rgba(0,0,0,0.75)",
    cursor: "pointer",
    "&:active": {
      boxShadow: "0px 0px 0px 0px rgba(0,0,0,0.75)"
    }
  },
  buttons: {
    display: "flex",
    marginTop: "35px"
  },
  button: {
    marginRight: 130,
    marginLeft: 130,
    background: "#36ABE0"
  },
  loadingButton: {
    display: "flex",
    width: "100%",
    justifyContent: "center"
  },
  logout: {
    cursor: "pointer",
    display: "flex",
    height: "70px",
    alignItems: "center"
  },
  containerCityPrime: {
    display: "flex",
    width: "180px",
    height: "55px",
    backgroundColor: "#473e69",
    borderRadius: "15px 15px 0 0",
    boxShadow: "0px 0px 16px 4px rgba(0,0,0,0.25)",
    margin: "0 5px",
    padding: "10px",
    cursor: "pointer"
  },
  containerViveMas: {
    display: "flex",
    width: "200px",
    height: "65px",
    borderRadius: "15px 15px 0 0",
    backgroundColor: "#36ABE0",
    boxShadow: "0px 0px 8px 3px rgba(0,0,0,0.20)",
    margin: "0 5px",
    padding: "10px 5px 4px 5px",
    cursor: "pointer"
  },
  containerCategory: {
    display: "flex",
    alignItems: "flex-end",
    flexDirection: "row"
  }
}));
