import { CITY_NOTISTACK_RESET, CITY_NOTISTACK_SET } from "../actions/types";
const INITIALSTATE = {
  active: false,
  config: { message: "", variant: "success", autoHideDuration: 3000 }
};

export default function(state = INITIALSTATE, action) {
  switch (action.type) {
    case CITY_NOTISTACK_SET:
      return {
        ...state,
        active: true,
        config: action.payload
      };
    case CITY_NOTISTACK_RESET:
      return {
        ...state,
        active: false,
        config: {}
      };
    default:
      return state;
  }
}
