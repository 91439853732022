import axios from "axios";

export const vivemasApi = axios.create({
  baseURL: `https://api.vivesmas.co`,
  headers: {
    "X-Requested-With": "XMLHttpRequest",
    "Content-Type": "application/json",
    application_id: "1"
  }
});
