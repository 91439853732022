import {
  getClaimedBenefits,
  claimBenefit,
  getUserCommerces
} from "../../api/vivieMas/userBenefits";
import {
  VIVE_ADD_BENEFITS,
  VIVE_GET_BENEFITS,
  VIVE_NOTISTACK_SET
} from "./types";

export const _VIVE_GET_BENEFITS = () => async (dispatch, getState) => {
  const user = getState().viveAuth.user;
  try {
    const userCommerces = await getUserCommerces(user.id);
    const commercesList = userCommerces.data.data;
    const commerceMap = commercesList.map(commerce => commerce?.commerce?.id);
    const res = await getClaimedBenefits(commerceMap);
    const data = res.data.data;
    dispatch({
      type: VIVE_GET_BENEFITS,
      payload: data
    });
  } catch (e) {
    console.log(e);
  }
};

export const _VIVE_ADD_BENEFIT = code => async dispatch => {
  try {
    await claimBenefit(code);
    dispatch({
      type: VIVE_NOTISTACK_SET,
      payload: {
        message: "Beneficio reclamado",
        variant: "success",
        active: true
      }
    });
    dispatch(_VIVE_GET_BENEFITS());
  } catch (e) {
    dispatch({
      type: VIVE_NOTISTACK_SET,
      payload: {
        message: e?.response?.data?.message || "Error al reclamar beneficio",
        variant: "Error",
        active: true
      }
    });
    console.log(e);
  }
};
