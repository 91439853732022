import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import { HomeCity, LoginCity } from "../screens";
import useCityAuth from "../hooks/useCityAuth";
import { useCityNotiSack } from "../CityRedux/actions/cityNotiStackActions";

const CustomNav = () => {
  const { url } = useRouteMatch();
  useCityAuth();
  useCityNotiSack();
  return (
    <Switch>
      <Route path={`${url}/inicio`}>
        <HomeCity />
      </Route>
      <Route path={`${url}/login`}>
        <LoginCity />
      </Route>
    </Switch>
  );
};

export default CustomNav;
