import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { VIVE_NOTISTACK_RESET } from "../viveMasRedux/actions/types";

export const useViveNotiSack = () => {
  const dispatch = useDispatch();
  const { active, config } = useSelector(state => state.viveNotiStack);
  const a = useSelector(state => state.viveNotiStack);

  const { closeSnackbar, enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    if (active) {
      enqueueSnackbar(config.message, {
        variant: config.variant,
        autoHideDuration: config.autoHideDuration
      });
      dispatch({ type: VIVE_NOTISTACK_RESET });
    }
  }, [active]);
};
