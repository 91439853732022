import axios from "axios";

export const SecureRequest = () => {
  const token = localStorage.getItem("viveMasToken");
  console.log(token);
  return axios.create({
    headers: {
      "X-Requested-With": "XMLHttpRequest",
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    },
    baseURL: "https://api.vivesmas.co"
  });
};
