import React, { useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Home } from "./screens";
import "./App.css";
import { Provider } from "react-redux";
import store from "./CityRedux/store";
import CityPrimeStack from "./navigation/cityprimeStack";
import VivemasStack from "./navigation/vivemasStack";
import viveStore from "./viveMasRedux/store";

function App() {
  useEffect(() => {});
  return (
    <Router>
      <Switch>
        <Route path="/vive-mas">
          <Provider store={viveStore}>
            <VivemasStack />
          </Provider>
        </Route>
        <Route path="/city-prime">
          <Provider store={store}>
            <CityPrimeStack />
          </Provider>
        </Route>
        <Route path="/">
          <Home />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
