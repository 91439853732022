import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";

import { LOGIN_VER_VIVE } from "../viveMasRedux/actions/authViveActions";

const useViveMasAuth = () => {
  const dispatch = useDispatch();
  const isAuthenticatedVive = useSelector(
    state => state.viveAuth.isAuthenticatedVive
  );
  const { url } = useRouteMatch();
  const history = useHistory();
  useEffect(() => {
    dispatch(LOGIN_VER_VIVE())
      .then(() => {
        console.info("IsAuth", isAuthenticatedVive);
        if (!isAuthenticatedVive) {
          history.push(`${url}/login`);
        } else {
          history.push(`${url}/inicio`);
        }
      })
      .catch(e => {
      });
  }, [isAuthenticatedVive]);
};

export default useViveMasAuth;
