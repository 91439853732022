import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import { LoginVive, HomeVive } from "../screens";
import useViveMasAuth from "../hooks/useViveMasAuth";
import { useViveNotiSack } from "../hooks/useViveNotiSack";

const CustomNav = () => {
  const { url } = useRouteMatch();
  useViveMasAuth();
  useViveNotiSack();
  return (
    <Switch>
      <Route path={`${url}/inicio`}>
        <HomeVive />
      </Route>
      <Route path={`${url}/login`}>
        <LoginVive />
      </Route>
    </Switch>
  );
};

export default CustomNav;
