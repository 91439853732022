import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";

import { LOGIN_VER } from "../CityRedux/actions/authActions";

const useCityAuth = () => {
  const dispatch = useDispatch();
  const isAuthenticatedCity = useSelector(
    state => state.auth.isAuthenticatedCity
  );
  const { url } = useRouteMatch();
  const history = useHistory();
  useEffect(() => {
    dispatch(LOGIN_VER())
      .then(() => {
        console.info("IsAuth", isAuthenticatedCity);
        if (!isAuthenticatedCity) {
          history.replace(`${url}/login`);
        } else {
          history.replace(`${url}/inicio`);
        }
      })
      .catch(e => {
      });
  }, [isAuthenticatedCity]);
};

export default useCityAuth;
