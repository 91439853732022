import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { LOGO_IMG, LOGO_VIVE } from "../../assets/images";
import { useHistory, useRouteMatch } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  containerHome: {
    width: "100vw",
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  containerCity: {
    background: "#473e69",
    width: "300px",
    height: "300px",
    margin: "0 55px 0 0 ",
    display: "flex",
    overflow: "hidden",
    alignItems: "center",
    justifyContent: "center",
    alignContent: "center",
    cursor: "pointer",
    "@media (min-width: 100px) and (max-width: 500px)": {
      margin: "0 0 20px 0"
    }
  },
  containerVive: {
    backgroundColor: "#36ABE0",
    width: "300px",
    height: "300px",
    margin: " 0 0 0 45px",
    display: "flex",
    overflow: "hidden",
    alignItems: "center",
    justifyContent: "center",
    alignContent: "center",
    cursor: "pointer",
    "@media (min-width: 100px) and (max-width: 500px)": {
      margin: "20px 0 0 0"
    }
  },
  imageContainerCity: {
    maxWidth: "200px",
    objectFit: "contain"
  },
  imageContainerVive: {
    maxWidth: "150px",
    objectFit: "contain"
  },
  title: {
    display: "block",
    margin: "44px",
    fontSize: "35px",
    textAlign: "center"
  },
  containerPlatform: {
    display: "flex",
    flexDirection: "row",
    "@media (min-width: 100px) and (max-width: 500px)": {
      flexDirection: "column"
    }
  }
}));

const Home = props => {
  const history = useHistory();
  const classes = useStyles();
  const pageSubmit = e => {
    history.push(`/${e}`);
  };

  return (
    <div className={classes.containerHome}>
      <div className={classes.title}>ESCOGE LA PLATAFORMA A UTILIZAR</div>
      <div className={classes.containerPlatform}>
        <div
          className={classes.containerCity}
          onClick={() => pageSubmit("city-prime/inicio")}
        >
          <img
            className={classes.imageContainerCity}
            src={LOGO_IMG}
            alt={"City Prime"}
          />
        </div>
        <div
          className={classes.containerVive}
          onClick={() => pageSubmit("vive-mas/inicio")}
        >
          <img
            className={classes.imageContainerVive}
            src={LOGO_VIVE}
            alt={"Vive mas"}
          />
        </div>
      </div>
    </div>
  );
};

export default Home;
