// ERRORS
export const VIVE_GET_ERRORS = "vive.getErrors";
export const VIVE_CLEAR_ERRORS = "vive.clearErrors";
// AUTH
export const VIVE_USER_LOADED = "vive.userLoaded";
export const VIVE_USER_LOADING = "vive.userLoading";
export const VIVE_SET_CATEGORY_USER = "city.userCategory";
export const VIVE_AUTH_ERROR = "vive.authError";
export const VIVE_LOGIN_SUCCESS = "vive.loginSuccess";
export const VIVE_LOGIN_FAIL = "vive.loginFail";
export const VIVE_LOGOUT_SUCCESS = "vive.logoutSuccess";
export const VIVE_REGISTER_SUCCESS = "vive.registerSuccess";
export const VIVE_REGISTER_FAILED = "vive.registerFailed";
export const VIVE_LOADING = "vive.loading";
// BENEFITS
export const VIVE_GET_BENEFITS = "city.getBenefits";
export const VIVE_BENEFITS_LOADING = "city.benefitsLoading";
export const VIVE_ADD_BENEFITS = "city.addBenefits";
export const VIVE_EXPORT_BENEFITS_HISTORY = "city.exportBenefitsHistory";
export const VIVE_EXPORT_FAILED = "city.exportFailed";
// NOTISTACK
export const VIVE_NOTISTACK_SET = "VIVE_NOTISTACK_SET";
export const VIVE_NOTISTACK_RESET = "VIVE_NOTISTACK_RESET";
