import React from "react";

function Main({ children }) {
  return (
    <div>

      <section>{children}</section>

    </div>
  );
}

export default Main;
