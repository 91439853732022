import { vivemasApi } from "../../utils/request";
import { SecureRequest } from "../../utils/secureRequest";

export const loginOtp = async data => {
  const res = await vivemasApi.post("/auth/otp", { id_token: data.id_token });
  return res.data;
};

export const loginAdmin = async data => {
  return await vivemasApi.post("auth/login", { ...data });
};

export const reauthenticate = async data => {
  const request = SecureRequest();
  return await request.get("auth/profile");
};
