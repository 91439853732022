import {
  VIVE_LOADING,
  VIVE_LOGIN_FAIL,
  VIVE_LOGIN_SUCCESS,
  VIVE_LOGOUT_SUCCESS
} from "../actions/types";
import {LOADING} from "../../CityRedux/actions/types";

const INITIAL_STATE = {
  token: localStorage.getItem("viveMasToken"),
  isAuthenticatedVive: null,
  isLoading: false,
  user: null
};

export default function(state = INITIAL_STATE, { payload, type }) {
  switch (type) {
    case VIVE_LOADING:
      return { ...state, isLoading: true };
    case VIVE_LOGIN_SUCCESS:
      return {
        ...state,
        user: payload,
        isAuthenticatedVive: true,
        isLoading: false
      };
    case VIVE_LOGIN_FAIL:
      return {
        ...state,
        isAuthenticatedVive: false,
        isLoading: false
      };

    case VIVE_LOGOUT_SUCCESS:
      return {
        ...state,
        accessToken: null,
        token: null,
        user: payload,
        isAuthenticatedVive: false,
        isLoading: false
      };
    default:
      return state;
  }
}
