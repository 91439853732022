import { loginAdmin, reauthenticate } from "../../api/vivieMas/auth";
import {
  VIVE_LOGIN_SUCCESS,
  VIVE_LOGIN_FAIL,
  VIVE_LOGOUT_SUCCESS,
  VIVE_NOTISTACK_SET,
  VIVE_LOADING
} from "./types";

export const LOGIN_VIVE = data => async dispatch => {
  try {
    const res = await loginAdmin(data);
    localStorage.setItem("viveMasToken", res.data.access_token);
    dispatch({ type: VIVE_LOGIN_SUCCESS, payload: res.data });
    dispatch({
      type: VIVE_NOTISTACK_SET,
      payload: { message: "Logged In", variant: "success", active: true }
    });
  } catch (e) {
    dispatch({ type: VIVE_LOGIN_FAIL });
    dispatch({
      type: VIVE_NOTISTACK_SET,
      payload: { message: "Logging error", variant: "error", active: true }
    });
    console.log(e);
  }
};

export const LOGIN_VER_VIVE = () => async dispatch => {
  dispatch({ type: VIVE_LOADING });
  try {
    const res = await reauthenticate();
    dispatch({ type: VIVE_LOGIN_SUCCESS, payload: res.data });
  } catch (e) {
    dispatch({ type: VIVE_LOGIN_FAIL });
  }
};

export const _LOGOUT_VIVE = () => dispatch => {
  localStorage.removeItem("viveMasToken");
  dispatch({
    type: VIVE_LOGOUT_SUCCESS
  });
  dispatch({
    type: VIVE_NOTISTACK_SET,
    payload: { message: "Logged Out", variant: "success", active: true }
  });
};
