import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { CITY_NOTISTACK_RESET } from "../actions/types";

export const useCityNotiSack = () => {
  const dispatch = useDispatch();
  const { active, config } = useSelector(state => state.cityNotiStack);

  const { closeSnackbar, enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    if (active) {
      enqueueSnackbar(config.message, {
        variant: config.variant,
        autoHideDuration: config.autoHideDuration
      });
      dispatch({ type: CITY_NOTISTACK_RESET });
    }
  }, [active]);
};
