import { MESSAGE, CLEAR_MESSAGE, LOADING_BUTTON } from "../actions/types";

const INITIAL_STATE = {
  msg: null,
  loadingButton: null
};

export default function(state = INITIAL_STATE, { type, payload }) {
  switch (type) {
    case MESSAGE:
      return {
        ...state,
        msg: payload
      };
    case CLEAR_MESSAGE:
      return {
        ...state,
        msg: null
      };
    case LOADING_BUTTON:
      return {
        ...state,
        loadingButton: payload
      };
    default:
      return state;
  }
}
