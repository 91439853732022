import { combineReducers } from "redux";
import {
  viveAuthReducer,
  viveBenefitsReducer,
  viveNotiStackReducer
} from "./index";

export default combineReducers({
  viveBenefit: viveBenefitsReducer,
  viveNotiStack: viveNotiStackReducer,
  viveAuth: viveAuthReducer
});
