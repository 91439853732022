import React, { Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import { ScanCard } from "./index";
import moment from "moment";
import Modal from "./Modal";
import { VIVEMAS_S3_CREDENTIALS } from "../../utils/constants";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper
  }
}));

export default function FolderList({ items = [], city = true }) {
  const [open, setOpen] = React.useState(false);
  const [data, setData] = React.useState();
  const showMore = el => {
    setData(el);
    setOpen(!open);
  };

  const classes = useStyles();

  return city ? (
    <Fragment>
      {data && open === true && (
        <Modal open={open} el={data} handleClose={showMore} />
      )}
      <List className={classes.root}>
        {items.map((el, index) => {
          console.info("dasdad", el.benefit?.commerce);
          return (
            <ScanCard
              onClick={() => showMore(el)}
              image={el.benefit?.establishment?.logo || ""}
              key={index}
              location={el.benefit?.commerce?.name}
              benefit={el.benefit.name}
              reader={
                el?.user_establishment?.first_name ||
                "" + " " + el?.user_establishment?.last_name ||
                ""
              }
              client={
                el?.user_client?.first_name ||
                "" + " " + el?.user_client?.last_name ||
                ""
              }
              date={moment(el.date_redeem).format("h:mm:ss a DD-MM-YY")}
            />
          );
        })}
      </List>
    </Fragment>
  ) : (
    <Fragment>
      {data && open === true && (
        <Modal open={open} el={data} handleClose={showMore} city={city} />
      )}
      <List className={classes.root}>
        {items.map((el, index) => {
          console.info("elementX", el);
          return (
            <ScanCard
              onClick={() => showMore(el)}
              image={VIVEMAS_S3_CREDENTIALS.base + el?.benefit?.image || ""}
              key={index}
              location={el.benefit?.commerce?.name}
              benefit={el?.benefit?.title}
              client={
                el?.user?.first_name || el?.user?.last_name
                  ? `${el?.user?.first_name} ${el?.user?.last_name}`
                  : "Usuario eliminado permantemente"
              }
              date={
                el.reclaimed &&
                moment(el.reclaimed).format("h:mm:ss a DD-MM-YY")
              }
            />
          );
        })}
      </List>
    </Fragment>
  );
}
