// ERRORS
export const GET_ERRORS = "city.getErrors";
export const CLEAR_ERRORS = "city.clearErrors";
// AUTH
export const USER_LOADED = "city.userLoaded";
export const USER_LOADING = "city.userLoading";
export const SET_CATEGORY_USER = "city.userCategory";
export const AUTH_ERROR = "city.authError";
export const LOGIN_SUCCESS = "city.loginSuccess";
export const LOGIN_FAIL = "city.loginFail";
export const LOGOUT_SUCCESS = "city.logoutSuccess";
export const REGISTER_SUCCESS = "city.registerSuccess";
export const REGISTER_FAILED = "city.registerFailed";
export const LOADING = "city.loading";
// BENEFITS
export const GET_BENEFITS = "city.getBenefits";
export const BENEFITS_LOADING = "city.benefitsLoading";
export const ADD_BENEFITS = "city.addBenefits";
export const EXPORT_BENEFITS_HISTORY = "city.exportBenefitsHistory";
export const EXPORT_FAILED = "city.exportFailed";
// USERS
export const GET_USERS = "city.getUsers";
export const ADD_USER = "city.addUser";
export const EXPORT_USERS_HISTORY = "city.exportUsersHistory";
// MESSAGE
export const MESSAGE = "city.message";
export const CLEAR_MESSAGE = "city.clearMessage";
export const LOADING_BUTTON = "city.LoadingButton";
// NOTISTACK
export const CITY_NOTISTACK_SET = "CITY_NOTISTACK_SET";
export const CITY_NOTISTACK_RESET = "CITY_NOTISTACK_RESET";
