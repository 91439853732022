import { VIVE_ADD_BENEFITS, VIVE_GET_BENEFITS } from "../actions/types";
const INITIAL_STATE = {
  loading: false,
  benefits: []
};

export default function(state = INITIAL_STATE, { type, payload }) {
  switch (type) {
    case VIVE_GET_BENEFITS:
      return {
        ...state,
        benefits: payload
      };

    case VIVE_ADD_BENEFITS:
      return {
        ...state,
        benefits: [...state.benefits, payload],
        loading: false
      };
    default:
      return state;
  }
}
