import { MESSAGE, LOADING_BUTTON } from "./types";

export const GET_MESSAGE = message => dispatch => {
  dispatch({
    type: MESSAGE,
    payload: message
  });
  setTimeout(() => {
    dispatch(CLEAR_MESSAGE());
  }, 3000);
};

export const CLEAR_MESSAGE = () => {
  return {
    type: MESSAGE
  };
};

export const SET_LOADING_BUTTON = e => {
  return {
    type: LOADING_BUTTON,
    payload: e
  };
};
