import { SecureRequest } from "../../utils/secureRequest";

export const getClaimedBenefits = async commerces => {
  console.info(
    `/users-benefits?where[status]=RECLAIMED&where[$$benefit.commerce.id$$][$in]=[${commerces}]`
  );
  return await SecureRequest().get(
    `/users-benefits?where[status]=RECLAIMED&where[$$benefit.commerce.id$$][$in]=${commerces}`
  );
};

export const claimBenefit = async code => {
  return await SecureRequest().post(`users-benefits/reclaim/${code}`);
};

export const getUserCommerces = user_id => {
  return SecureRequest().get(`commerces-users/?where[user_id]=${user_id}`);
};
