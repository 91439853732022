import { applyMiddleware, createStore } from "redux";
import thunk from "redux-thunk";
import { viveRootReducer } from "./reducers";

const INITIAL_STATE = {};
const middleware = [thunk];

const viveStore = createStore(
  viveRootReducer,
  INITIAL_STATE,
  applyMiddleware(...middleware)
);

export default viveStore;
