import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { colors } from "../../utils/theme";
import { connect } from "react-redux";
import { LOGIN } from "../../CityRedux/actions/authActions";
import { useHistory } from "react-router-dom";
import { SET_CATEGORY } from "../../CityRedux/actions/userActions";
import { CircularProgress } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  avatar: {
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%" // Fix IE 11 issue.
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  home: {
    margin: theme.spacing(0, 0, 2)
  },
  imageContainer: {
    width: "30%",
    height: "30%"
  },
  container: {
    display: "flex",
    alignItems: "center",
    height: "100vh",
    background: "#473e69"
  },
  input: {
    color: colors.white
  },
  inputText: {
    "& label.Mui-focused": {
      color: "white"
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "white"
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "white"
      },
      "&:hover fieldset": {
        borderColor: "white"
      },
      "&.Mui-focused fieldset": {
        borderColor: "white"
      }
    }
  }
}));

function SignIn(props) {
  let history = useHistory();

  function handleClick() {
    history.push("/inicio");
  }

  const [input, setInput] = useState({});
  const [msg, setMsg] = useState(null);

  const handleInputChange = e =>
    setInput({
      ...input,
      [e.currentTarget.name]: e.currentTarget.value
    });

  const onSubmit = e => {
    const { email, password } = input;
    e.preventDefault();
    const user = {
      email: email,
      password: password
    };
    // Attemp to login new user
    props.login(user);
  };
  useEffect(() => {
    props.categoryUser("City");
  });
  const classes = useStyles();
  return !props.isLoading ? (
    <div className={classes.container}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <img
            className={classes.imageContainer}
            src={require("../../assets/images/main_logo.png")}
            alt={"Alt"}
          />

          <form onSubmit={onSubmit} className={classes.form} noValidate>
            <TextField
              className={classes.inputText}
              InputLabelProps={{
                style: {
                  width: "100%",
                  color: "white"
                }
              }}
              InputProps={{
                className: classes.input
              }}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              onChange={handleInputChange}
            />
            <TextField
              className={classes.inputText}
              InputLabelProps={{
                style: {
                  width: "100%",
                  color: "white"
                }
              }}
              InputProps={{
                className: classes.input
              }}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={handleInputChange}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Iniciar sesión
            </Button>
          </form>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            className={classes.home}
            onClick={() => {
              history.push("/");
            }}
          >
            Inicio
          </Button>
        </div>
        <Box mt={8}></Box>
      </Container>
    </div>
  ) : (
    <div
      style={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: colors.main
      }}
    >
      <CircularProgress color={"secondary"} />
    </div>
  );
}

const mapStateToProps = state => ({
  isAuthenticatedCity: state.auth.isAuthenticatedCity,
  error: state.error,
  userCategory: state.user.category,
  isLoading: state.auth.isLoading
});

function mapDispatchToProps(dispatch) {
  return {
    login: user => dispatch(LOGIN(user)),
    categoryUser: category => dispatch(SET_CATEGORY(category))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
